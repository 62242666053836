import ReactModal, { Props } from 'react-modal'
import classnames from 'classnames'

import styles from './ModalContainer.module.css'

// This will work with both Next.js and Storybook
ReactModal.setAppElement('#__next,#root')

// Reset default styles, so we can use our own
ReactModal.defaultStyles = {}

export type ModalContainerProps = Props & {
  title?: string
  onExit: () => void
  closeable?: boolean
  isPageScroll?: boolean
}

const ModalContainer = ({
  title,
  children,
  onExit,
  closeable = true,
  shouldCloseOnOverlayClick,
  shouldCloseOnEsc,
  overlayClassName,
  bodyOpenClassName,
  htmlOpenClassName,
  className,
  isPageScroll = false,
  ...rest
}: ModalContainerProps) => {
  return (
    <ReactModal
      contentLabel=
      onRequestClose={onExit}
      shouldCloseOnEsc={shouldCloseOnEsc ?? closeable}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick ?? closeable}
      overlayClassName={classnames(styles.overlay, overlayClassName)}
      className={classnames(styles.content, { [styles.hideScroll]: isPageScroll }, className)}
      bodyOpenClassName={classnames(styles.bodyOpen, bodyOpenClassName)}
      htmlOpenClassName={classnames(styles.htmlOpen, htmlOpenClassName)}
      {...rest}>
      <>{children && children}</>
    </ReactModal>
  )
}

export default ModalContainer
